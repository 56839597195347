<template>
  <div>
    <init-loading :isInitLoading.sync="isInitLoading"></init-loading>

    <div class='main-page-content'>
      <div>
        <el-row>
          <el-col :span='24'>
            <el-form :model='searchCondition' inline>
              <!--              <el-form-item label="负责人" prop="leader_id">-->
              <!--                <UserSelect :leader-id.sync="searchCondition.leader_id"  @selected="selectedLeader"/>-->
              <!--              </el-form-item>-->
              <el-form-item label=''>
                <el-date-picker
                  v-model='searchCondition.ym'
                  type='month'
                  value-format='yyyy-MM'
                  format='yyyy年MM月'
                  placeholder='选择月份' style='width: 140px'>
                </el-date-picker>
              </el-form-item>
              <el-form-item label=''>
                <artist-search-custom :type='``' @handleSelect='selectArtist' :show-first-group='false'
                                      style='width: 100%' />
              </el-form-item>
              <el-form-item label='发起人'>
                <el-input v-model='searchCondition.leader_name' clearable placeholder='发起人：支持模糊搜索'></el-input>
                <!--                <el-select v-model='searchCondition.leader_id'>-->

                <!--                </el-select>-->
              </el-form-item>
              <el-form-item label='状态'>
                <el-select v-model='searchCondition.progress_status' style='width: 120px' clearable>
                  <el-option :label='option.label' :value='option.value' v-for='(option,key) in progressStatusOptions'
                             :key='key' :style='`text-align: center;border-radius: 3px;margin:1px;border: #7d8086 1px solid;background-color:${option.color_value}`'></el-option>
                </el-select>
              </el-form-item>
<!--              <el-form-item label='排期类型'>-->
<!--                <el-select v-model='searchCondition.type' style='width: 120px' clearable>-->
<!--                  <el-option :label='option.label' :value='option.value' v-for='(option,key) in typeOptions'-->
<!--                             :key='key' :style='`text-align: center;border-radius: 3px;margin:1px;border: #7d8086 1px solid;background-color:${option.color_value}`'></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
              <el-form-item>
                <el-button type='primary' @click='handleQuery' icon='el-icon-search'>查询</el-button>
<!--                <el-button type='primary' @click='handleAdd' icon='el-icon-plus'-->
<!--                           v-if="userPermissions.indexOf('lb_schedule_create')>-1">新增-->
<!--                </el-button>-->
              </el-form-item>
              <el-form-item style='float: right'>
                <el-radio-group v-model='mod'>
                  <el-radio-button label='Calendar'>日历展示</el-radio-button>
                  <el-radio-button label='Table'>列表展示</el-radio-button>
                  <el-radio-button label='Mine'>我参与的</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div>
        <!--      日历展示-->
        <ScheduleCalendar ref='refScheduleCalendar' :search-condition='searchCondition'
                          v-if="mod==='Calendar'" :lb-type='0'
                          @dragEdit='dragEdit' @handleCmd='handleCmd' />
        <!--      列表展示-->
        <ScheduleTable ref='refScheduleTable' :mod='mod' :data='dataList' :search-condition='searchCondition'
                       v-if="mod==='Table'"
                       @handleCmd='handleCmd'  :lb-type='0' :progress-status-options='progressStatusOptions' />

        <ScheduleTable ref='refScheduleMineTable' :mod='mod' :data='dataList' :search-condition='searchCondition'
                       v-if="mod==='Mine'"
                       @handleCmd='handleCmd'   :lb-type='0' :progress-status-options='progressStatusOptions' />
      </div>
    </div>

    <!--    创建直播排期-->
    <CreateEdit :visible.sync='open' @close='handleQuery' :id='currId' :default-form-data='defaultFormData' />

    <!--    日志-->
    <LbScheduleLog :info-id='currId' :visible.sync='openLog' />
  </div>

</template>

<script>
import ScheduleCalendar from '@/pages/lb/ScheduleCalendar'
import ScheduleTable from '@/pages/lb/ScheduleTable'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import CreateEdit from '@/pages/lb/CreateEdit'
import ApeDrawer from '@/components/ApeDrawer'
import LbScheduleLog from '@/pages/lb/LbScheduleLog'
import LeaderSearch from '@/pages/lb/components/LeaderSearch'
import UserSelect from '@/pages/lb/components/UserSelect'
import { mapGetters } from 'vuex'


export default {
  name: 'ScheduleIndex',
  components: {
    UserSelect,
    LeaderSearch,
    LbScheduleLog,
    ApeDrawer, CreateEdit, PageHeaderLayout, ArtistSearchCustom, ScheduleCalendar, ScheduleTable
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  data() {
    return {
      mod: 'Calendar',
      searchCondition: {type:0, ym: null, artist_id: null, leader_id: '', progress_status: null, mod: this.mod },//
      dataList: [],//直播数据列表
      open: false,
      dialogTitle: '直播排期',
      openLog: false,//日志模态框
      currId: null,
      orderSort: { start_at: 'desc', id: 'desc' },//默认排序规则
      defaultFormData: {},
      progressStatusOptions: [
        { label: '新创建', value: 1, color_value: '#FFFFFF', class_name: 'status-new' },
        { label: '已发需求', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '初选完成', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '确认完成', value: 4, color_value: '#E6A23C', class_name: 'status-ack' },
        { label: '锁定表格', value: 5, color_value: '#F56C6C', class_name: 'status-lock' }
      ],
      typeOptions: [
        { label: '未设置', value: 0, color_value: '#FFFFFF', class_name: 'status-new' },
        { label: '红人选品', value: 1, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '直播定品', value: 2, color_value: '#409EFF', class_name: 'status-original' },
      ],
      isInitLoading: true
    }
  },
  watch: {
    // 'searchCondition.ym': {
    //   immediate: true,
    //   handler(val) {
    //     if (val) {
    //       let ymArr = val.split('-')
    //       this.searchCondition.year = parseInt(ymArr[0])
    //       this.searchCondition.month = parseInt(ymArr[1])
    //       this.handleQuery()
    //     }
    //   }
    // },
    mod: {
      handler(val) {
        this.searchCondition.mod = val
        this.handleQuery()
      }
    }
  },
  methods: {
    dragEdit(val) {
      if (val) {
        this.handleQuery()
      }
    },
    defaultTime() {
      const end = new Date()
      //年份选择：默认当月
      this.searchCondition.ym = end.getFullYear() + '-' + (end.getMonth() + 1)
    },

    selectArtist(selectInfo) {
      // console.log(selectInfo)
      this.searchCondition.artist_id = selectInfo.artist_id
      this.searchCondition.nickname = selectInfo.nickname
      this.searchCondition.group_id = selectInfo.group_id
      this.searchCondition.group_name = selectInfo.group_name
      this.handleQuery()
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.ym) {
        condition.ym = this.searchCondition.ym
      }

      if (this.searchCondition.artist_id) {
        condition.artist_id = this.searchCondition.artist_id
      }
      if (this.searchCondition.progress_status) {
        condition.progress_status = this.searchCondition.progress_status
      }
      if (this.searchCondition.leader_id) {
        condition.leader_id = this.searchCondition.leader_id
      }
      if (this.searchCondition.leader_name) {
        condition.leader_name = this.searchCondition.leader_name
      }
      if (this.searchCondition.type) {
        condition.type = this.searchCondition.type
      }
      return condition
    },
    handleQuery() {

      this.$nextTick(() => {
        if (this.mod === 'Calendar') {
          if (this.$refs['refScheduleCalendar'])
            this.$refs['refScheduleCalendar'].handleQuery()
        } else if (this.mod === 'Table') {
          if (this.$refs['refScheduleTable'])
            this.$refs['refScheduleTable'].handleQuery()
        } else {
          if (this.$refs['refScheduleMineTable'])
            this.$refs['refScheduleMineTable'].handleQuery()
        }
        this.isInitLoading = false

      })

      // this.$notify.info('查询')
      // let searchCondition = this.handleSearchCondition()
      // Object.assign(searchCondition, { order: this.orderSort })
      // let { list } = await this.$api.getLbScheduleMonthList(searchCondition)
      // this.dataList = list
    },

    handleAdd(data) {
      this.currId = null
      let date = data.date ? data.date : data.fullDate
      if (data) {
        this.defaultFormData = { timeRange: [`${date} 09:00`, `${date} 18:00`] }
      }

      this.open = true
    },
    showDetail(row) {
      this.jumpToPage('lbScheduleDetail', { id: row.id })
    },
    showLog(row) {
      this.currId = row.id || null
      this.openLog = true
    },
    showNotify(row) {
      this.currId = row.id || null
      this.open = true
    },
    jumpToPage(pageName, params) {
      this.$router.push({
        name: pageName,
        params: params,
        query: params
      })
      // const { href } = this.$router.resolve({
      //   name: pageName,
      //   params: params,
      //   query: params
      // })
      // window.open(href, '_blank')
    },
    selectProduct(row) {
      this.currId = row.id || null
      this.jumpToPage('productSelection', { lb_id: row.id })
    },
    async delInfo(id) {
      let info = await this.$api.delLbSchedule(id)
      if (info) {
        await this.handleQuery()
        this.msgSuccess('删除成功')
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const id = row.id
      this.$confirm(`是否确认删除排期【${row.date}/${row.nickname}/${row.platform_name}】?`, '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delInfo(id)
      }).catch(() => {
        this.msgWarn('取消操作')
      })
    },
    handleCmd(cmd, row) {
      // this.$emit('handleCmd',cmd,this.currentRow)
      switch (cmd) {
        case 'detail':
          // this.$notify.info('查看排期')
          this.showDetail(row)
          break
        case 'selection':
          // this.$notify.info('查看选品')
          this.selectProduct(row)
          break
        case 'selection_ack':
          // this.$notify.info('查看选品-确认')
          this.jumpToPage('productSelectionAck', { 'id': row.id })
          break
        case 'create':
          // this.$notify.info('添加排期')
          this.handleAdd(row)
          break
        case 'log':
          this.showLog(row)
          break
        case 'delete':
          this.handleDelete(row)
          break
        // case 'notify':
        //   this.$notify.info('通知')
        //   this.showNotify(row)
        //   break;
      }
    },
    selectedLeader(item) {
      this.searchCondition.leader_id = item.value
      this.handleQuery()
    }
  },
  created() {
    this.defaultTime()
    // this.handleQuery()
  }
}
</script>

<style scoped>

</style>
